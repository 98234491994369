angular.module('Artakija',
    ['Artakija.config',
        'ngAnimate',
        'ngSanitize',
        'ngTouch',
        'ui.router',
        'rip.api',
        'LocalStorageModule',
        'smart-table',
        'ui.bootstrap',
        'ui.select'
    ],
    ["$provide", function ($provide) {
        $provide.value("$locale", {
            "DATETIME_FORMATS": {
                "AMPMS": [
                    "priešpiet",
                    "popiet"
                ],
                "DAY": [
                    "sekmadienis",
                    "pirmadienis",
                    "antradienis",
                    "trečiadienis",
                    "ketvirtadienis",
                    "penktadienis",
                    "šeštadienis"
                ],
                "ERANAMES": [
                    "prieš Kristų",
                    "po Kristaus"
                ],
                "ERAS": [
                    "pr. Kr.",
                    "po Kr."
                ],
                "FIRSTDAYOFWEEK": 6,
                "MONTH": [
                    "Sausis",
                    "Vasaris",
                    "Kovas",
                    "Balandis",
                    "Gegužė",
                    "Birželis",
                    "Liepa",
                    "Rugpjūtis",
                    "Rugsėjis",
                    "Spalis",
                    "Lapkritis",
                    "Gruodis",
                ],
                "SHORTDAY": [
                    "sk",
                    "pr",
                    "an",
                    "tr",
                    "kt",
                    "pn",
                    "š"
                ],
                "SHORTMONTH": [
                    "saus.",
                    "vas.",
                    "kov.",
                    "bal.",
                    "geg.",
                    "birž.",
                    "liep.",
                    "rugp.",
                    "rugs.",
                    "spal.",
                    "lapkr.",
                    "gruod."
                ],
                "STANDALONEMONTH": [
                    "Sausis",
                    "Vasaris",
                    "Kovas",
                    "Balandis",
                    "Gegužė",
                    "Birželis",
                    "Liepa",
                    "Rugpjūtis",
                    "Rugsėjis",
                    "Spalis",
                    "Lapkritis",
                    "Gruodis",
                ],
                "WEEKENDRANGE": [
                    5,
                    6
                ],
                "fullDate": "EEEE, MMMM d, y",
                "longDate": "MMMM d, y",
                "medium": "MMM d, y h:mm:ss a",
                "mediumDate": "MMM d, y",
                "mediumTime": "h:mm:ss a",
                "short": "y-MM-dd h:mm a",
                "shortDate": "y-MM-dd",
                "shortTime": "h:mm a"
            },
            "NUMBER_FORMATS": {
                "CURRENCY_SYM": "$",
                "DECIMAL_SEP": ".",
                "GROUP_SEP": ",",
                "PATTERNS": [
                    {
                        "gSize": 3,
                        "lgSize": 3,
                        "maxFrac": 3,
                        "minFrac": 0,
                        "minInt": 1,
                        "negPre": "-",
                        "negSuf": "",
                        "posPre": "",
                        "posSuf": ""
                    },
                    {
                        "gSize": 3,
                        "lgSize": 3,
                        "maxFrac": 2,
                        "minFrac": 2,
                        "minInt": 1,
                        "negPre": "-\u00a4",
                        "negSuf": "",
                        "posPre": "\u00a4",
                        "posSuf": ""
                    }
                ]
            },
            "id": "lt",
            "localeID": "lt",
            // "pluralCat": function (n, opt_precision) {
            //     var i = n | 0;
            //     var vf = getVF(n, opt_precision);
            //     if (i === 1 && vf.v === 0) {
            //         return PLURAL_CATEGORY.ONE;
            //     }
            //     return PLURAL_CATEGORY.OTHER;
            // }
        });
    }])

    .constant('PROJECT_NAME', 'Artakija')

    .run(function AppRun($rootScope, $state, $location, $api, LS) {
        var token = LS.getParam('token');
        var user = LS.getParam('user');
        if (user) {
            $rootScope.user = user;
        }

        // Menu provider
        $rootScope.menu_list = [{
            name: 'home',
            title: 'Pagrindinis',
            state: 'home',
            url: '/home',
            icon: 'fa-dashboard',
            role: ['ROLE_ADMIN']
        }, {
            name: 'zero_consumption',
            title: 'Nuliniai suvartojimai',
            state: 'zero_consumption',
            url: '/zero-consumption',
            icon: 'fa-dashboard',
            role: ['ROLE_ADMIN']
        }, {
            name: 'premises',
            title: 'Patalpos',
            state: 'premises',
            url: '/premises',
            icon: 'fa-building',
            role: ['ROLE_ADMIN']
        }, {
            name: 'user_premises',
            title: 'Vartotojų patalpos',
            state: 'user_premises',
            url: '/user_premises',
            icon: 'fa-building',
            role: ['ROLE_ADMIN']
        }, {
            name: 'ecl_controllers',
            title: 'Ecl valdikliai',
            state: 'ecl_controllers',
            url: '/ecl_controllers',
            icon: 'fa fa-sitemap',
            role: ['ROLE_ADMIN']
        }, {
            name: 'riser_controllers',
            title: 'Stovų valdikliai',
            state: 'riser_controllers',
            url: '/riser_controllers',
            icon: 'fa fa-sitemap',
            role: ['ROLE_ADMIN']
        }, {
            name: 'indicators',
            title: 'Skaitikliai',
            state: 'indicators',
            url: '/indicators',
            icon: 'fa-table',
            role: ['ROLE_ADMIN']
        }, {
            name: 'indicators_consumption',
            title: 'Skaitiklių parodymai',
            state: 'indicators_consumption',
            url: '/indicators_consumption',
            icon: 'fa-table',
            role: ['ROLE_ADMIN']
        },
            //     {
            //     name: 'my_premises',
            //     title: 'Mano patalpos',
            //     state: 'my_premises',
            //     url: '/my_premises',
            //     icon: 'fa-building',
            //     role: ['ROLE_CONSUMER']
            // },
            {
                name: 'user',
                title: 'Vartotojai',
                state: null,
                url: '/user',
                icon: 'fa-user',
                role: ['ROLE_ADMIN'],
                subs: [{
                    name: 'user.add_person',
                    title: 'Pridėti fizinį vartotoją',
                    state: 'user.add_person',
                    url: '/user/add_person',
                    icon: 'fa-user',
                    role: ['ROLE_ADMIN']
                }, {
                    name: 'user.add_company',
                    title: 'Pridėti juridinį vartotoją',
                    state: 'user.add_company',
                    url: '/user/add_company',
                    icon: 'fa-user',
                    role: ['ROLE_ADMIN']
                }, {
                    name: 'user.add_company_person',
                    title: 'Pridėti juridinio asmens atstovą',
                    state: 'user.add_company_person',
                    url: '/user/add_company_person',
                    icon: 'fa-user',
                    role: ['ROLE_ADMIN']
                }]
            }, {
                name: 'admin',
                title: 'Administratoriai',
                state: null,
                url: '/admin',
                icon: 'fa-user-secret',
                role: ['ROLE_ADMIN'],
                subs: [{
                    name: 'admin',
                    title: 'Administratoriai',
                    state: 'admin',
                    url: '/admin',
                    icon: 'fa-user-secret',
                    role: ['ROLE_ADMIN']
                }/*,{
                name: 'admin.object',
                title: 'Administratoriaus objektai',
                state: 'admin.object',
                url: '/admin/object',
                icon: 'fa-user-secret',
                role: ['ROLE_ADMIN']
            }*/, {
                    name: 'admin.add_admin',
                    title: 'Pridėti administratorių',
                    state: 'admin.add_admin',
                    url: '/admin/add_admin',
                    icon: 'fa-user-secret',
                    role: ['ROLE_ADMIN']
                }/*,{
                name: 'admin.add_object',
                title: 'Pridėti objektą',
                state: 'admin.add_object',
                url: '/admin/add_object',
                icon: 'fa-user-secret',
                role: ['ROLE_ADMIN']
            }*/]
            }, {
                name: 'consumption',
                title: 'Šilumos suv. pagal daliklius',
                state: 'consumption',
                url: '/consumption',
                icon: 'fa-table',
                role: ['ROLE_ADMIN']
            }, {
                name: 'user_consumption',
                title: 'Šilumos suv. pagal daliklius',
                state: 'user_consumption',
                url: '/user_consumption',
                icon: 'fa-table',
                role: ['ROLE_CONSUMER']
            }, {
                name: 'indicators_readings',
                title: 'Skaitiklių parodymų archyvas',
                state: 'indicators_readings',
                url: '/indicators_readings',
                icon: 'fa-table',
                role: ['ROLE_ADMIN']
            }, {
                name: 'user_indicators_readings',
                title: 'Skaitiklių parodymai',
                state: 'user_indicators_readings',
                url: '/user_indicators_readings',
                icon: 'fa-table',
                role: ['ROLE_CONSUMER']
            }, {
                name: 'building_reading_insert',
                title: 'Bendras namo šilumos skaitiklis',
                state: 'building_reading_insert',
                url: '/building_reading_insert',
                icon: 'fa-table',
                role: ['ROLE_ADMIN']
            }];

        // Initiate left menu tree plugin (admin lte bug fix)
        $(document).ready(function () {
            $('[data-widget="tree"]').tree();
        });

        // Get current path
        $rootScope.$watch(function () {
            return $location.path();
        }, function () {
            $rootScope.currentPath = $location.path();
        });

        // Log out
        $rootScope.logOut = function () {
            LS.removeLS('token');
            LS.removeLS('user');
            $state.go('login');
        };

        // Hide menu item if user has no appropriate role
        $rootScope.hideMenu = function (menu_roles, menu_name) {
            var user = LS.getParam('user');
            var user_roles = user.roles;
            var hide = true;
            var hideAccessPoints = true;

            angular.forEach(user.accessPoints, function (data) {
                if (data === menu_name) {
                    hideAccessPoints = false;
                }
            });

            angular.forEach(menu_roles, function (data) {
                if (user_roles.indexOf(data) !== -1) {
                    hide = false;
                }
            });

            return hideAccessPoints ? hideAccessPoints : hide;
        };

        // Check authorization
        if (token && user) {
            var roles = user.roles;

            $api.header('X-AUTH-TOKEN', token);

            if (roles.indexOf('ROLE_ADMIN') !== -1) {
                if (user.accessPoints.includes('home')) {
                    $state.go('home');
                } else {
                    $state.go(user.accessPoints[0])
                }
            } else if (roles.indexOf('ROLE_CONSUMER') !== -1) {
                $state.go('user_consumption');
            }
        } else {
            $state.go('login');
        }
    })

    .config(function appConfig($stateProvider, $urlRouterProvider, $apiProvider) {

        // If unmatched url
        $urlRouterProvider.otherwise('');

        // Prod http://artakija.lt:30443/
        // Stage http://artakija.lt:50443/

        // Configure api
        // $apiProvider.rootUrl('https://konfig.artakija.lt/');
        $apiProvider.rootUrl('https://konfigtest.artakija.lt/');
        // $apiProvider.rootUrl('http://artakija.lt:50443/');
        // $apiProvider.rootUrl('http://localhost:8000/');

        $apiProvider.header('content-type', 'application/json');

        $apiProvider
            .post('login', 'api/login').end()

            // indicators
            .get('getInactiveMeters', 'manager/indicator/inactive').end()
            .get('getZeroValueIndicatorsList', 'manager/indicator/zero-consumption').end()
            .get('getBuildingIndicatorTypes', 'manager/indicator_types/:id').end()

            // building
            .get('getBuildings', 'manager/buildings').end()
            .get('getBuildingTypes', 'manager/buildings/type').end()
            .get('deleteBuilding', 'manager/buildings/:id').end()
            .get('getCities', 'manager/building/cities').end()

            // person building
            .get('getUserBuildings', 'person/buildings').end()
            .get('getUserCities', 'person/building/cities').end()

            // reports
            .get('getAdministratorReports', 'manager/reports/:reportType').end()

            // Ecl controllers
            .get('getEclBuildings', 'manager/ecl/buildings').end()
            .get('getBuildingEclControllers', 'manager/ecl/building/:id').end()
            .get('getEclController', 'manager/ecl/controller/:controller').end()
            .post('setDesTComfort', 'manager/ecl/set_des_t_comfort/:controller').end()
            .post('setDesTSaving', 'manager/ecl/set_des_t_saving/:controller').end()

            // Riser controllers
            .get('getRiserControllerBuildings', 'manager/riser_controllers/buildings').end()
            .get('getRiserController', 'manager/riser_controllers/:id').end()

            // Premise
            .get('getPremises', 'manager/premises/:id').end()
            .get('getPremise', 'manager/premise/:id').end()
            .post('createPremise', 'manager/premise/create').end()
            .post('editPremise', 'manager/premise/edit/:id').end()
            .post('deletePremise', 'manager/premise/delete/:id').end()
            .get('getPremiseTypes', 'manager/premise-type').end()

            .get('getUserPremises', 'manager/building/consumers/:id').end()
            .get('getUsers', 'manager/consumers').end()
            .post('createUserPremise', 'manager/building/consumers/create').end()
            .post('deleteUserPremise', 'manager/building/consumers/delete/:id').end()

            .get('getMyPremises', 'person/premises').end()

            .post('createUser', 'manager/person/create').end()
            .post('createCompany', 'manager/company/create').end()
            .get('getCompanies', 'manager/companies').end()
            .get('getCompanyPersons', 'manager/company-persons/:id').end()
            .post('createCompanyPerson', 'manager/company-person/create').end()
            .get('deleteCompanyPerson', 'manager/company-person/delete/:id').end()

            .get('getPersons', 'manager/person').end()

            .get('getAdmins', 'manager/administrator').end()
            .get('getAdminObject', 'manager/administrator/:id').end()
            .get('getAdminCompanies', 'manager/companies/administrator').end()
            .post('createAdmin', 'manager/administrator/create').end()
            .get('deleteAdmin', 'manager/administrator/delete/:id').end()

            .post('getUserConsumption', 'person/buildings/usage/:id').end()

            .get('getConsumption', 'manager/buildings/usage/:id').end()
            .get('exportConsumption', 'manager/buildings/usage/export/:id').end()

            .get('getIndicators', 'manager/indicators/:id').end()
            .get('getIndicatorsConsumption', 'manager/indicators_consumption/:id').end()

            .get('getIndicatorsReadings', 'manager/buildings/indicator_data/:id').end()
            // .get('exportIndicatorsReadings', 'manager/buildings/indicator_data/export/:id').end()

            .get('getUserIndicatorsReadings', 'person/premise/usage/:id').end()

            .get('getLmulines', 'manager/buildings/lmulines/:id').end()

            .get('getIndicatorsByBuilding', 'manager/building/indicator/:id').end()
            .post('insertBuildingReading', 'manager/building/indicator/:id').end()
            .get('getBuildingUsageValue', 'manager/building/indicator/value/:id').end()
        ;

        $stateProvider

            .state('root', {
                abstract: true,
                url: '',
                views: {
                    'root': {
                        templateUrl: 'root.html'
                    },
                    'header@root': {
                        templateUrl: 'header.html'
                    },
                    'left_menu@root': {
                        templateUrl: 'left_menu.html'
                    },
                    'footer@root': {
                        templateUrl: 'footer.html'
                    }
                }
            })

            .state('login', {
                url: '/login',
                views: {
                    'root': {
                        templateUrl: 'login.html',
                        controller: 'LoginController'
                    }
                }
            })

            .state('home', {
                url: '/home',
                parent: 'root',
                templateUrl: 'home.html',
                controller: 'HomeController'
            })

            .state('zero_consumption', {
                url: '/zero_consumption',
                parent: 'root',
                templateUrl: 'zero_consumption.html',
                controller: 'ZeroValueIndicatorsList'
            })

            .state('user', {
                url: '/user',
                parent: 'root',
                templateUrl: 'user.html',
                controller: 'UserController'
            })

            .state('user.add_person', {
                url: '/user/add_person',
                parent: 'root',
                templateUrl: 'user_person_edit.html',
                controller: 'UserPersonEditController'
            })

            .state('user.add_company', {
                url: '/user/add_company',
                parent: 'root',
                templateUrl: 'user_company_edit.html',
                controller: 'UserCompanyEditController'
            })

            .state('user.add_company_person', {
                url: '/user/add_company_person',
                parent: 'root',
                templateUrl: 'user_company_person_edit.html',
                controller: 'UserCompanyPersonEditController',
                param: {company: null}
            })

            .state('premises', {
                url: '/premises',
                parent: 'root',
                templateUrl: 'premises.html',
                controller: 'PremisesController',
                params: {object: null}
            })

            .state('premises.edit_premises', {
                url: '/premises/edit_premises/:id',
                parent: 'root',
                templateUrl: 'premises_edit.html',
                controller: 'PremisesEditController',
                params: {object: null}
            })

            .state('user_premises', {
                url: '/user_premises',
                parent: 'root',
                templateUrl: 'user_premises.html',
                controller: 'UserPremisesController',
                params: {object: null}
            })

            .state('user_premises.add_premise', {
                url: '/user_premises/add_premise',
                parent: 'root',
                templateUrl: 'user_premises_edit.html',
                controller: 'UserPremisesEditController',
                params: {object: null}
            })

            // .state('my_premises', {
            //     url: '/my_premises',
            //     parent: 'root',
            //     templateUrl: 'my_premises.html',
            //     controller: 'MyPremisesController'
            // })

            .state('admin', {
                url: '/admin',
                parent: 'root',
                templateUrl: 'admin.html',
                controller: 'AdminController'
            })

            .state('admin.object', {
                url: '/admin/object',
                parent: 'root',
                templateUrl: 'admin_object.html',
                controller: 'AdminObjectController',
                params: {row: null}
            })

            .state('admin.add_admin', {
                url: '/admin/add_admin',
                parent: 'root',
                templateUrl: 'admin_edit.html',
                controller: 'AdminEditController'
            })

            .state('admin.add_object', {
                url: '/admin/add_object',
                parent: 'root',
                templateUrl: 'admin_object_edit.html',
                controller: 'AdminObjectEditController'
            })

            .state('consumption', {
                url: '/consumption',
                parent: 'root',
                templateUrl: 'consumption.html',
                controller: 'ConsumptionController'
            })

            .state('user_consumption', {
                url: '/user_consumption',
                parent: 'root',
                templateUrl: 'user_consumption.html',
                controller: 'UserConsumptionController'
            })

            .state('indicators_readings', {
                url: '/indicators_readings',
                parent: 'root',
                templateUrl: 'indicators_readings.html',
                controller: 'IndicatorsReadingsController'
            })

            .state('user_indicators_readings', {
                url: '/user_indicators_readings',
                parent: 'root',
                templateUrl: 'user_indicators_readings.html',
                controller: 'UserIndicatorsReadingsController'
            })

            .state('ecl_controllers', {
                url: '/ecl_controllers',
                parent: 'root',
                templateUrl: 'ecl_controllers.html',
                controller: 'EclControllersController'
            })

            .state('ecl_controllers.edit_ecl_controller', {
                url: '/ecl_controllers/edit_ecl_controller/:controller',
                parent: 'root',
                templateUrl: 'ecl_controllers_edit.html',
                controller: 'EclControllersEditController',
            })

            .state('riser_controllers', {
                url: '/riser_controllers',
                parent: 'root',
                templateUrl: 'riser_controllers.html',
                controller: 'RiserControllerController'
            })

            .state('indicators', {
                url: '/indicators',
                parent: 'root',
                templateUrl: 'indicators.html',
                controller: 'IndicatorsController'
            })

            .state('indicators_consumption', {
                url: '/indicators_consumption',
                parent: 'root',
                templateUrl: 'indicators_consumption.html',
                controller: 'IndicatorsConsumptionController'
            })

            .state('building_reading_insert', {
                url: '/building_reading_insert',
                parent: 'root',
                templateUrl: 'building_reading_insert.html',
                controller: 'BuildingReadingInsertController'
            })

            .state('lmulines', {
                url: '/lmulines',
                parent: 'root',
                templateUrl: 'lmulines.html',
                controller: 'LmulinesController'
            })

        ;

    });
